import axios from "axios";

export default axios.create({
    /*baseURL: "https://ua-pvr-test.discvision.de/api",    headers: {
        "Content-type": "application/json"
    }*/

    baseURL: "https://attivaregistrazione.digiquest.it/api", headers: {
        "Content-type": "application/json"
    }
    /*baseURL: "http://localhost:8080/api",    headers: {
        "Content-type": "application/json"
    }*/
});



