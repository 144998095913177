import Vue from "vue"
import Vuex from "vuex"
import router from "../router";
import TransactionDataService from "../services/TransactionDataService";

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        transactions: [],
        totalRows: 0,
        currentUnit: null,
        accessToken: '',
        role: null,
        macAddress: '',
        boxType: '',
        pvrNumber: '',
        user: {
            id: 1,
            name: "Guest",
            zip: "",
            street: "",
            country: "",
            city: "",
            newsletter: 0,
            email: '',
        },
        transactionId: '',
        purchased: false,
        filename: '',
    },
    actions: {
        GET_ALL_TRANSACTIONS: function ({commit}) {
            let config = { 'headers': { 'x-access-token': this.state.accessToken } };
            TransactionDataService.getAll(config)
                .then(response => {
                    console.log("Transactions store");
                    console.log(response.data);
                    commit('SET_TRANSACTIONS', {transactions: response.data,});
                    commit('SET_TOTAL_ROWS', {totalRows: response.data.length,});
                })
                .catch(e => {
                    console.log(e);
                    console.log(e.response);
                    if (e.response.status === 401) {
                        router.push({name: 'home'});
                    }
                })
        }
    },
    mutations: {
        SET_TRANSACTIONS: (state, {transactions}) => {
            state.transactions = transactions
        },
        SET_TOTAL_ROWS: (state, {totalRows}) => {
            state.totalRows = totalRows
        },
        SET_MAC_ADDRESS: (state, {macAddress}) => {
            state.macAddress = macAddress
        },
        SET_BOX_TYPE: (state, {boxType}) => {
            state.boxType = boxType
        },
        SET_ACCESS_TOKEN: (state, {accessToken}) => {
            console.log('SET ACCESS TOKEN!');
            console.log(accessToken);
            state.accessToken = accessToken
        },
        SET_ROLE: (state, {role}) => {
            state.role = role
        },
        SET_PVR_NUMBER: (state, {pvrNumber}) => {
            state.pvrNumber = pvrNumber
        },
        SET_USER: (state, {user}) => {
            state.user = user
        },
        SET_TRANSACTION_ID: (state, {transactionId}) => {
            state.transactionId = transactionId
        },
        SET_PURCHASED: (state, {purchased}) => {
            state.purchased = purchased
        },
        SET_FILENAME: (state, {filename}) => {
            state.filename = filename
        }
    },
    getters: {
        macAddress: state => {
            return state.macAddress;
        },
        boxType: state => {
            return state.boxType;
        },
        user: state => {
            return state.user
        },
        pvrNumber: state => {
            return state.pvrNumber
        },
        accessToken: state => {
            return state.accessToken
        },
        transactionId: state => {
            return state.transactionId
        },
        purchased: state => {
            return state.purchased
        },
        filename: state => {
            return state.filename
        }
    },
    modules: {},
})

export default store
